import { useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FieldAgentProfile } from "../../db/interfaces";
import { axiosInstance } from "../../redux/apis/axios";
import { ADMIN_TOKEN } from "../../state_manager/constants";
import "../../styles/farmer_add.css";
import "../../styles/field_agent_add.css";
import { AddFieldAgent } from "../../redux/apis/fieldAgents.service";

const FormInput: React.FC<{
  title: string;
  type?: string;
  name: string;
  isSelect?: boolean;
  data: any;
  setValue: any;
  selectValues?: string[];
}> = ({ title, type, name, isSelect, data, setValue, selectValues }) => {
  return (
    <div className="form_input">
      <h3>{title}</h3>
      {isSelect ? (
        <select
          name={name}
          id=""
          value={data ? data[name] : ""}
          onChange={(e) => setValue({ ...data, [name]: e.target.value })}
        >
          {selectValues?.map((selectValue: string, i: number) => {
            return (
              <option key={selectValue} value={selectValue}>
                {selectValue}
              </option>
            );
          })}
        </select>
      ) : (
        <input
          type={type ?? "text"}
          name={name}
          value={data ? data[name] : ""}
          onChange={(e) => setValue({ ...data, [name]: e.target.value })}
        />
      )}
    </div>
  );
};

const FieldAgentAdd = () => {
  const navigation = useNavigate();
  const formDataObj: FieldAgentProfile = {
    name: "",
    age: "",
    gender: "male",
    ghanaCardNumber: "",
    id: "",
    imgUrl: "",
    numberOfFarmers: 0,
    phoneNumber: 0,
  };

  const [formData, setFormData] = useState(formDataObj);

  const submitForm = async () => {
    const response = await AddFieldAgent(formData);
  };

  return (
    <div className="field_agent_add" data-aos="fade-left">
      <div className="close_container">
        <div className="close_btn" onClick={() => navigation(-1)}>
          <MdArrowBack />
        </div>
      </div>

      <div className="form_cont">
        <div className="form_inner">
          <FormInput
            title="Name"
            name="name"
            data={formData}
            setValue={setFormData}
          />
          <FormInput
            title="Age"
            type="number"
            name="age"
            data={formData}
            setValue={setFormData}
          />
          <FormInput
            title="Gender"
            name="gender"
            selectValues={["Male", "Female"]}
            isSelect
            data={formData}
            setValue={setFormData}
          />
          <FormInput
            title="Phone Number"
            type="number"
            name="phonenumber"
            data={formData}
            setValue={setFormData}
          />
          <FormInput
            title="Ghana Card Number"
            name="ghanaCardNumber"
            data={formData}
            setValue={setFormData}
          />
          <FormInput
            title="Number of Farmers"
            type="number"
            name="numberOfFarmers"
            data={formData}
            setValue={setFormData}
          />

          <div className="form_submit_cont">
            <button className="btn" onClick={submitForm}>
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldAgentAdd;
