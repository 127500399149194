import {
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlinePersonAdd,
  MdOutlineSearch,
  MdPersonOutline,
} from "react-icons/md";
import "../../styles/farmers.css";
import FarmersTable from "../../components/farmers_table";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { Loading2 } from "../../components/loading";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../redux/apis/axios";
import { ADMIN_TOKEN } from "../../state_manager/constants";
import { toast, ToastContainer } from "react-toastify";
import {
  HiOutlinePlusSm,
  HiOutlineRefresh,
  HiOutlineTrash,
} from "react-icons/hi";
import SmAnalysis from "../../components/sm_analysis";
import { useDispatch, useSelector } from "react-redux";
import { requestFarmers } from "../../redux/actions/farmers";

import { convertFarmerQuery } from "../../functions/farmers";
import { farmersActions } from "../../redux/slice/farmers/farmersSlice";
import { getCachedToken } from "../../service/common";

const Farmers = () => {
  const navigate = useNavigate();

  const [farmerListLoading, setFarmerListLoading] = useState<boolean>(false);
  const [farmerDataLoading, setFarmerDataLoading] = useState<boolean>(false);
  const [farmersQuery, setFarmersQuery] = useState<any>(null);
  const [searchData, setSearchData] = useState({ keyword: "", type: "name" });

  const { farmers_added_today, farmers, isLoading, meta } = useSelector(
    (state: any) => state.farmers
  );

  const { user } = useSelector((state: any) => state.admin);

  const dispatch = useDispatch();

  const handleClick = (code: any) => {
    dispatch(farmersActions.FarmerDetails(code));
    navigate("details");
  };

  useEffect(() => {
    requestFarmers(dispatch, user?.company_id, user?.id);
  }, []);

  useEffect(() => {
    setFarmerListLoading(false);
  }, [farmers]);

  const handleFarmerPagination = (url: string) => {
    setFarmerListLoading((prev) => !prev);
    const queryString = url.split("?")[1];
    const paginate = queryString ? queryString : "";
    requestFarmers(dispatch, user?.company_id, user?.id, paginate);
  };

  // SEARCH FOR FARMERS OF A FARMER

  const onSearchSubmit = async () => {
    if (!searchData.keyword) {
      return;
    }
    setFarmerDataLoading(true);
    const token = getCachedToken();

    await axiosInstance
      .post("/admin/api/search/farmers", searchData, {
        headers: { Authorization: `Bearer ${token?.token}` },
      })
      .then((res) => {
        const { msg, code } = res.data;
        if (code !== 200) toast(msg, { toastId: "farmersToast" });
        if (code === 200) {
          let _farmersQuery = convertFarmerQuery(msg);
          setFarmersQuery(_farmersQuery);
        }
        setFarmerDataLoading(false);
      })
      .catch((error) => {
        alert("Error getting farmers " + error);
        setFarmerDataLoading(false);
      });
  };

  const handleReload = () => {
    const url =
      "https://backend.lbhfarm.com/admin/api/company/list/farmers?page=1";
    handleFarmerPagination(url);
    setFarmersQuery(null);
    setSearchData({ keyword: "", type: "name" });
  };

  if (isLoading) return <Loading2 />;

  return (
    <div className="farmers">
      <ToastContainer
        hideProgressBar
        position="top-center"
        autoClose={1500}
        containerId={"farmersToast"}
      />

      <SmAnalysis
        title="Farmers"
        Icon1={MdPersonOutline}
        Icon2={MdOutlinePersonAdd}
        total={meta?.total ?? 0}
        totalToday={farmers_added_today}
      />

      <div className="search_filter_cont" data-aos="fade-up" id="top">
        <div className="search_cont">
          <MdOutlineSearch className="search_icon" />
          <input
            type="text"
            placeholder="search farmer"
            value={searchData.keyword}
            onChange={(e) =>
              setSearchData({ ...searchData, keyword: e.target.value })
            }
            onKeyDown={(e) => e.key === "Enter" && onSearchSubmit()}
          />
        </div>
        <div className="filter_cont">
          <select
            name="filter"
            className="dropdown"
            onChange={(e) =>
              setSearchData({ ...searchData, type: e.target.value })
            }>
            <option value="name">Name</option>
            <option value="region">Region</option>
            <option value="town">Town</option>
          </select>
        </div>
      </div>

      <div className="actions_cont" data-aos="fade-up" data-aos-delay="150">
        <div className="actions_cont_inner">
          <div className="actions_btn_cont">
            <div className="action_btn" title="delete table">
              <HiOutlineTrash style={{ color: "red" }} />
            </div>

            <div
              className="action_btn"
              title="refresh table"
              onClick={() => {
                handleReload();
              }}>
              <HiOutlineRefresh />
            </div>

            <div
              className="action_btn"
              title="add farmer"
              onClick={() => navigate("/farmeradd")}>
              <HiOutlinePlusSm title="add farmer" />
            </div>
          </div>
          <div className="table_pagination_cont">
            <div className="table_page_numbers">
              <h4>
                {farmersQuery?.meta.from ?? meta?.from} -{" "}
                {farmersQuery?.meta.to ?? meta?.to} of{" "}
                {farmersQuery?.meta.total ?? meta?.total}
              </h4>
            </div>

            <div className="table_page_control">
              <div
                className={`control_btn ${
                  meta?.current_page === 1 ? "control_inactive" : ""
                }`}
                title="Previous Page"
                onClick={() => {
                  (farmersQuery?.meta.prev_page_url ?? meta?.prev_page_url) &&
                    handleFarmerPagination(
                      farmersQuery?.meta.prev_page_url ??
                        meta?.prev_page_url ??
                        ""
                    );
                }}>
                <MdOutlineChevronLeft />
              </div>

              {(farmersQuery?.meta.links ?? meta?.links)
                ?.slice(1, -1)
                .map((link: any) => {
                  return (
                    <div key={link.label}>
                      <h4
                        className={`cursor-pointer hover:font-semibold ${
                          meta.current_page?.toString() ===
                            link.label?.toString() && "font-semibold"
                        }`}
                        onClick={() => {
                          if (
                            meta.current_page?.toString() !==
                            link.label?.toString()
                          ) {
                            handleFarmerPagination(link.url);
                          }
                        }}>
                        {link?.label}
                      </h4>
                    </div>
                  );
                })}

              <div
                className="control_btn control_inactive"
                title="Next Page"
                onClick={() => {
                  (farmersQuery?.meta.next_page_url ?? meta?.next_page_url) &&
                    handleFarmerPagination(
                      farmersQuery?.meta.next_page_url ??
                        meta?.next_page_url ??
                        ""
                    );
                }}>
                <MdOutlineChevronRight />
              </div>

              <div
                className="control_btn control_inactive"
                title="First Page"
                onClick={() => {
                  (farmersQuery?.meta.prev_page_url ?? meta?.prev_page_url) &&
                    handleFarmerPagination(
                      farmersQuery?.meta.first_page_url ??
                        meta?.first_page_url ??
                        ""
                    );
                }}>
                <MdOutlineExpandLess />
              </div>
              <div
                className="control_btn control_inactive"
                title="Last Page"
                onClick={() => {
                  meta?.next_page_url &&
                    handleFarmerPagination(
                      farmersQuery?.meta.last_page_url ??
                        meta?.last_page_url ??
                        ""
                    );
                }}>
                <MdOutlineExpandMore />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="farmer_table_top" className="list_farmers_cont">
        <div className="table_loading_cont">
          {farmerDataLoading && (
            <div className="table_loading_cont_inner">
              <div className="table_loading_indicator"></div>
            </div>
          )}
        </div>

        <FarmersTable
          farmerListLoading={farmerListLoading}
          listFarmers={farmersQuery?.farmers ?? farmers}
          handleClick={handleClick}
        />
        <div className="back_to_top">
          <h4
            style={{ cursor: "pointer" }}
            onClick={() => {
              const topElement = document.getElementById("top");
              if (topElement) {
                topElement.scrollIntoView({ behavior: "smooth" });
              }
            }}>
            GO TO TOP
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Farmers;
