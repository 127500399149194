import React, { useEffect, useState } from "react";
import { GetCountries } from "../../redux/apis/helpers.service";

const ServiceAddForm = () => {
  const [formData, setFormData] = useState({
    country: "",
    serviceType: "",
    location: "",
  });
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [countries, setCountries] = useState<
    { name: string; id: number }[] | null
  >(null);

  useEffect(() => {
    GetCountries().then((response: any) => {
      const { code, msg } = response.data;
      if (code !== 200) return;
      setCountries(msg);
    });
  }, []);

  // const countries = ['Country A', 'Country B', 'Country C']; // Replace with your list of countries
  const serviceTypes = ["Service Type 1", "Service Type 2", "Service Type 3"]; // Replace with your list of service types

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRadioChange = (e: any) => {
    setFormData({
      ...formData,
      location: e.target.value,
    });
  };

  useEffect(() => {
    if (formData.location) {
      setErrorMsg("");
    }
  }, [formData.location]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Handle form submission logic here
    if (formData.location === "") {
      setErrorMsg("Please select a location !");
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <label
          htmlFor="serviceTypeSelect"
          className="text-[#C4C4C4] text-sm block">
          Service Type
        </label>
        <div className="border-[2px] text-sm rounded-lg  pr-3">
          <select
            className="w-full h-full py-2 cursor-pointer rounded-lg"
            id="serviceTypeSelect"
            name="serviceType"
            value={formData.serviceType}
            required
            onChange={handleInputChange}>
            <option value="" className="mr-4">
              Select Service
            </option>
            {serviceTypes.map((serviceType) => (
              <option key={serviceType} value={serviceType}>
                {serviceType}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="countrySelect" className="text-[#C4C4C4] text-sm block">
          Country
        </label>
        <div className="border-[2px] text-sm rounded-lg  pr-3">
          <select
            className="w-full h-full py-2 cursor-pointer rounded-lg"
            id="countrySelect"
            name="country"
            value={formData.country}
            required
            onChange={handleInputChange}>
            <option value="">Select a Country</option>
            {countries?.map((country) => (
              <option key={country?.id} value={country?.id}>
                {country?.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-8">
        <p className="text-[#C4C4C4] text-sm mb-3">
          Select the offering Location
          {errorMsg && (
            <span className="text-red-300 text-xs block">{errorMsg}</span>
          )}
        </p>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <input
              className="cursor-pointer w-[14px] h-[14px] block accent-[#37847E]"
              type="radio"
              name="location"
              value="Region"
              checked={formData.location === "Region"}
              onChange={handleRadioChange}
            />
            <label className="font-medium text-sm">Region</label>
          </div>
          <div className="flex items-center gap-2">
            <input
              className="cursor-pointer w-[14px] h-[14px] block accent-[#37847E]"
              type="radio"
              name="location"
              value="National"
              checked={formData.location === "National"}
              onChange={handleRadioChange}
            />
            <label className="font-medium text-sm">National</label>
          </div>
          <div className="flex items-center gap-2">
            <input
              className="cursor-pointer w-[14px] h-[14px] block accent-[#37847E]"
              type="radio"
              name="location"
              value="International"
              checked={formData.location === "International"}
              onChange={handleRadioChange}
            />
            <label className="font-medium text-sm">International</label>
          </div>
        </div>
      </div>

      <div className="sub_btn rounded-lg text-sm bg-[#37847E] text-white cursor-pointer border-[2px] border-[#37847E] hover:font-medium hover:bg-[#2a6b66] delay-100 w-[80%] h-fit">
        <button type="submit" className=" h-full m-0 py-2 px-8">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ServiceAddForm;
