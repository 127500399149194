import React, { useCallback } from "react";
import {
  MdArrowBack,
  MdOutlineHome,
  MdOutlineLock,
  MdOutlineMail,
  MdOutlinePerson,
  MdOutlinePhoto,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddImage from "../../../assets/images/add_image.png";
import { useDispatch, useSelector } from "react-redux";
import { createClient } from "../../../redux/actions/clients";
import { addClientActions } from "../../../redux/slice/client/addClientSlice";
import { STORE_ACTION_TYPES } from "../../../redux/types";
import { useEffect, useState } from "react";
import { GetCountries, GetStates } from "../../../redux/apis/helpers.service";
import { useDropzone } from "react-dropzone";
// import { log } from "console";

const ClientAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, clientData, failedMsg, success } = useSelector(
    (state: any) => state.addClient
  );

  const admin_id = useSelector((state: any) => state.admin.user.id);

  const [countries, setCountries] = useState<
    { name: string; id: number }[] | null
  >(null);

  const [cities, setCities] = useState<{ name: string; id: number }[] | null>(
    null
  );

  const [logo, setLogo] = useState<any>(null);
  const [logoImg, setLogoImg] = useState<any>(null);

  if (failedMsg) console.log(failedMsg);

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setLogo(acceptedFiles);

      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        setLogoImg(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    dispatch(
      addClientActions[STORE_ACTION_TYPES.ADD_CLIENT.UPDATE_INFO]({
        [name]: value,
      })
    );
  };

  const submitForm = () => {
    const updatedClientData = {
      ...clientData,
      logo: logo,
      admin_id,
    }
    createClient(dispatch, updatedClientData );
  };

  useEffect(() => {
    if (success) {
      toast.success("Company Created Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setTimeout(() => {
        navigate("/clients");
        dispatch(addClientActions[STORE_ACTION_TYPES.ADD_CLIENT.FAILED]("")); // this is to set success to false
      }, 3000);
    }
  }, [dispatch, navigate, success]);

  useEffect(() => {
    GetCountries().then((response: any) => {
      const { code, msg } = response.data;
      if (code !== 200) return;
      setCountries(msg);
    });
  }, []);

  useEffect(() => {
    if (!clientData?.country_id) return;
    GetStates(clientData?.country_id).then((response: any) => {
      const { code, msg } = response.data;
      if (code !== 200) return;
      setCities(msg);
    });
  }, [clientData?.country_id]);

  return (
    <div className="field_agent_add" data-aos="fade-left">
      <div className="close_container">
        <div className="close_btn" onClick={() => navigate(-1)}>
          <MdArrowBack />
        </div>
      </div>

      <div className="form_cont">
        <ToastContainer
          position="top-center"
          hideProgressBar
          autoClose={1500}
        />
        <div className="form_inner">
          <div
            style={{ width: "100%", maxWidth: "1200px", marginBottom: "30px" }}
          >
            <h1 className="font-bold text-2xl">Add Client</h1>
            <h4>Enter client information</h4>
          </div>

          <div className="w-full gap-5 grid md:grid-cols-2 ">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <h4>Company Name</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlinePerson size={20} className=" text-gray-400" />
                  </div>

                  <input
                    className="px-3"
                    type="text"
                    name="name"
                    value={clientData.name}
                    onChange={handleChange}
                    placeholder="company name"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <h4>Email</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlineMail size={20} className=" text-gray-400" />
                  </div>

                  <input
                    className=" flex-1 px-3 bg-[var(--bg1)]"
                    name="email"
                    value={clientData.email}
                    onChange={handleChange}
                    type="text"
                    placeholder="email"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <h4>City</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlineLock size={20} className=" text-gray-400" />
                  </div>

                  <input
                    className="px-3"
                    type="text"
                    name="city"
                    value={clientData.city}
                    onChange={handleChange}
                    placeholder="City"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <h4>Country</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 rounded-full border border-gray-200"></div>

                  <select
                    name="country_id"
                    value={clientData.country_id}
                    onChange={handleChange}
                    className="w-full px-3"
                    placeholder="company name"
                  >
                    <option value="">--choose country --</option>
                    {countries?.map((country) => {
                      return (
                        <option key={country?.id} value={country?.id}>
                          {country?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <h4>State / Province</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlineHome size={20} className=" text-gray-400" />
                  </div>
                  <select
                    name="state_id"
                    value={clientData.state_id}
                    onChange={handleChange}
                    className="w-full px-3"
                    placeholder="State name"
                  >
                    <option value="">--choose Region/State/Province --</option>
                    {cities?.map((state) => {
                      return (
                        <option key={state?.id} value={state?.id}>
                          {state?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="flex flex-col gap-5 md:mt-5">
                <div className="flex gap-3 items-center">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlinePhoto size={20} className="text-gray-400" />
                  </div>
                  <h4>Company Logo</h4>
                </div>

                <div
                  {...getRootProps()}
                  className={`w-[200px] h-[150px] p-3 flex flex-col gap-3 place-items-center rounded-xl border border-gray-300 border-dashed cursor-pointer ${
                    isDragActive ? "bg-gray-100" : ""
                  }`}
                >
                  {logoImg ? (
                    <img src={logoImg} className="h-16" alt="logo" />
                  ) : isDragActive ? (
                    <p>Drop the image here</p>
                  ) : (
                    <>
                      <img src={AddImage} className="h-16" alt="AddImage" />
                      <input {...getInputProps()} />
                      <p className="text-gray-400 text-xs text-center">
                        Click to browse or drag and drop an image to upload
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form_submit_cont">
            <button className="btn" onClick={submitForm}>
              {isLoading ? "LOADING....." : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientAdd;
