import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { BsFunnel } from "react-icons/bs";

type CycleStatus = "all" | "pending" | "progress" | "complete";
interface FilterMenuProps {
  handleFilter: (filter: CycleStatus) => void;
  selectedState: CycleStatus;
}
interface MenuTileProps {
  handleFilter: (filter: CycleStatus) => void;
  selectedState: CycleStatus;
  type: CycleStatus;
}

export const MenuTile = ({
  handleFilter,
  selectedState,
  type,
}: MenuTileProps) => {
  const title =
    type === "progress"
      ? "In Progress"
      : type.charAt(0).toUpperCase() + type.slice(1);
  return (
    <MenuItem
      onClick={() => handleFilter(type)}
      bg={selectedState === type ? "#05897C" : "transparent"}
      color={selectedState === type ? "white" : "#000"}
      _hover={{ bg: "#0FAC9C", color: "white" }}>
      {title}
    </MenuItem>
  );
};

const FilterMenu = ({ handleFilter, selectedState }: FilterMenuProps) => {
  return (
    <Menu>
      <MenuButton px={2} py={2} transition="all 0.2s">
        <div className="flex items-center gap-4 border border-gray-300 rounded-xl px-6 py-3 cursor-pointer">
          <BsFunnel />
          <p>Filter</p>
        </div>
      </MenuButton>
      <MenuList>
        <MenuTile
          handleFilter={handleFilter}
          selectedState={selectedState}
          type="all"
        />
        <MenuTile
          handleFilter={handleFilter}
          selectedState={selectedState}
          type="pending"
        />
        <MenuTile
          handleFilter={handleFilter}
          selectedState={selectedState}
          type="progress"
        />
        <MenuTile
          handleFilter={handleFilter}
          selectedState={selectedState}
          type="complete"
        />
      </MenuList>
    </Menu>
  );
};

export default FilterMenu;
