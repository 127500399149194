import "../../styles/cycles.css";
import CycleTabs from './cycle_tabs';


const Cycles = () => {

  return (
    <div className="clients">
      <div className='cycles_container !mt-0'>
        <CycleTabs/>
      </div>
  </div>

  )
}

export default Cycles





