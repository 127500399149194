import React, { useCallback } from "react";
import {
  MdArrowBack,
  MdOutlineHome,
  MdOutlineLock,
  MdOutlineMail,
  MdOutlinePerson,
  MdOutlinePhoto,
  MdOutlineLocalPhone,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddImage from "../../../assets/images/add_image.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCountries, getStates } from "../../../redux/actions/countryState";
import { useDropzone } from "react-dropzone";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { addAdminActions } from "../../../redux/slice/client/adminSlice";
import { createAdmin } from "../../../redux/actions/admin";

const AddAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatchAdminData: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const dispatchCountries: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const dispatchStates: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { adminData, message, isLoading } = useSelector(
    (state: any) => state?.addAdmin
  );

  const { countries } = useSelector((state: any) => state?.countriesState);

  const { states } = useSelector((state: any) => state?.countriesState);

  const admin_id = useSelector((state: any) => state.admin.user?.id);
  const company_id = useSelector(
    (state: any) => state.clients.clientDetails?.id
  );
  const [logo, setLogo] = useState<any>(null);
  const [logoImg, setLogoImg] = useState<any>(null);

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setLogo(acceptedFiles);

      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        setLogoImg(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    dispatch(addAdminActions.updateAdminDetails({ [name]: value }));
  };

  const submitForm = async () => {
    const updatedAdminData = {
      ...adminData,
      image: logo,
      admin_id,
      company_id,
    };
    await dispatchAdminData(createAdmin(updatedAdminData));

    if (message?.code && message.code === 200) {
      toast.success(message.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate('/client-details')
    } else {
      toast.error(message.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    dispatchCountries(getCountries());
    dispatchStates(getStates(adminData?.country_id));
  }, [dispatchCountries, dispatchStates, adminData?.country_id]);

  return (
    <div className="field_agent_add" data-aos="fade-left">
      <div className="close_container">
        <div className="close_btn" onClick={() => navigate(-1)}>
          <MdArrowBack />
        </div>
      </div>

      <div className="form_cont">
        <ToastContainer
          position="top-center"
          hideProgressBar
          autoClose={1500}
        />
        <div className="form_inner">
          <div
            style={{ width: "100%", maxWidth: "1200px", marginBottom: "30px" }}
          >
            <h1 className="font-bold text-2xl">Add Administrator</h1>
            <h4>Enter Admin information</h4>
          </div>

          <div className="w-full gap-5 grid md:grid-cols-2 ">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <h4>Name</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlinePerson size={20} className=" text-gray-400" />
                  </div>

                  <input
                    className="px-3"
                    type="text"
                    name="name"
                    value={adminData.name}
                    onChange={handleChange}
                    placeholder="company name"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <h4>Email</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlineMail size={20} className=" text-gray-400" />
                  </div>

                  <input
                    className=" flex-1 px-3 bg-[var(--bg1)]"
                    name="email"
                    // value={adminData.email}
                    onChange={handleChange}
                    type="text"
                    placeholder="email"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <h4>Phone number</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlineLocalPhone size={20} className=" text-gray-400" />
                  </div>

                  <input
                    className="px-3"
                    type="text"
                    name="phone"
                    value={adminData.city}
                    onChange={handleChange}
                    placeholder="0244630500"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <h4>Country</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 rounded-full border border-gray-200"></div>

                  <select
                    name="country_id"
                    value={adminData.country_id}
                    onChange={handleChange}
                    className="w-full px-3"
                    placeholder="company name"
                  >
                    <option value="">--choose country --</option>
                    {countries?.map((country: any) => {
                      return (
                        <option key={country?.id} value={country?.id}>
                          {country?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <h4>State / Province</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlineHome size={20} className=" text-gray-400" />
                  </div>
                  <select
                    name="state_id"
                    value={adminData.state_id}
                    onChange={handleChange}
                    className="w-full px-3"
                    placeholder="State name"
                  >
                    <option value="">--choose Region/State/Province --</option>
                    {adminData.country_id !== 0 &&
                      states?.map((state: any) => {
                        return (
                          <option key={state?.id} value={state?.id}>
                            {state?.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <h4>Password</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlineLock size={20} className=" text-gray-400" />
                  </div>

                  <input
                    className="px-3"
                    type="text"
                    name="password"
                    value={adminData.password}
                    onChange={handleChange}
                    placeholder="* * * * * * * *"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <h4>Confirm password</h4>
                <div className="flex p-3 border rounded-lg border-gray-200">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlineLock size={20} className=" text-gray-400" />
                  </div>

                  <input
                    className="px-3"
                    type="text"
                    name="password_confirmation"
                    value={adminData.password_confirmation}
                    onChange={handleChange}
                    placeholder="* * * * * * * *"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-5 md:mt-5">
                <div className="flex gap-3 items-center">
                  <div className="h-8 w-8 grid place-content-center rounded-full border border-gray-200">
                    <MdOutlinePhoto size={20} className="text-gray-400" />
                  </div>
                  <h4>Company Logo</h4>
                </div>

                <div
                  {...getRootProps()}
                  className={`w-[200px] h-[150px] p-3 flex flex-col gap-3 place-items-center rounded-xl border border-gray-300 border-dashed cursor-pointer ${
                    isDragActive ? "bg-gray-100" : ""
                  }`}
                >
                  {logoImg ? (
                    <img src={logoImg} className="h-16" alt="logo" />
                  ) : isDragActive ? (
                    <p>Drop the image here</p>
                  ) : (
                    <>
                      <img src={AddImage} className="h-16" alt="AddImage" />
                      <input {...getInputProps()} />
                      <p className="text-gray-400 text-xs text-center">
                        Click to browse or drag and drop an image to upload
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form_submit_cont">
            <button className="btn" onClick={submitForm}>
              {isLoading ? "LOADING....." : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
