import { MdOutlineCases, MdOutlineSearch } from "react-icons/md";
import "../../../styles/clients.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { Loading2 } from "../../../components/loading";
import SmAnalysis from "../../../components/sm_analysis";
// import { ClientProfile } from "../../db/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients } from "../../../redux/actions/clients";
import Paginator from "../../../components/paginator";
import { clientsActions } from "../../../redux/slice/client/clientsSlice";
import { ThunkDispatch } from "redux-thunk";

const Clients = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { clients, isLoading, meta, failedMsg } = useSelector(
    (state: any) => state.clients
  );
  const adminId = useSelector((state: any) => state.admin.user.id);
  const isSuperAdmin = useSelector(
    (state: any) => state.admin.user.is_super_admin
  );

  if (failedMsg) console.log(failedMsg);

  useEffect(() => {
    if (isSuperAdmin === 1) {
      dispatch(fetchClients(adminId));
    }
  }, [dispatch, isSuperAdmin, adminId]);

  const handleClick = (code: any) => {
    dispatch(clientsActions.ClientDetails(code));
    navigate("details");
  };

  if (isLoading) return <Loading2 />;
  return (
    <div className="clients">
      <div className="clients_inner">
        <SmAnalysis
          title="Clients"
          Icon1={MdOutlineCases}
          Icon2={MdOutlineCases}
          total={meta.total}
          totalToday={0}
        />

        <div className="search_filter_cont" data-aos="fade-up">
          <div className="search_cont">
            <MdOutlineSearch className="search_icon" />
            <input type="text" placeholder="search client" name="query" />
          </div>
        </div>

        <Paginator
          title="Add Client"
          addUrl="/clientadd"
          meta={meta}
          adminId={adminId}
        />

        {/* CLINETS TABLE */}
        <div className="list_farmers_cont">
          {!clients ? (
            <div
              style={{
                width: "100%",
                minHeight: "400px",
                display: "grid",
                placeItems: "center",
              }}>
              No Clients yet...
            </div>
          ) : (
            <table>
              <thead className="table_head">
                <tr className="table-row">
                  {["LOGO", "NAME", "EMAIL", "COUNTRY", "NO. OF FARMER"].map(
                    (tableHeadContent, i) => (
                      <th key={tableHeadContent} className="table_head_content">
                        {tableHeadContent}
                      </th>
                    )
                  )}
                </tr>
              </thead>

              <tbody>
                {clients && clients.length > 0 ? (
                  clients.map((client: any) => (
                    <tr
                      className="table-row"
                      onClick={() => handleClick(client.code)}
                      key={client.id}>
                      <td>
                        <Avatar
                          src={client.logo}
                          alt={client.name}
                          className="t_img"
                        />
                      </td>
                      <td>{client.name}</td>
                      <td>{client.email}</td>
                      <td>{client.country.name}</td>
                      <td>{client.farmers_count}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="table-row">
                    <td colSpan={5}>No data yet</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Clients;
