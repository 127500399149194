import { useState } from "react";
import { MdArrowBack, MdOutlineSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";
import "../../styles/farmer_transactions.css";
import { useSelector } from "react-redux";

interface Product {
  type: string;
  brand: string;
  quantity: string;
  weight: string;
  price: string;
  date: string;
}

const TransactionTile = ({ type, quantity, brand, price, weight }: Product) => {
  return (
    <div className="transaction_tile">
      <h4 className="text-center">{type}</h4>
      <h4 className="text-center">{brand}</h4>

      <h4 className="text-center">{quantity} Bags</h4>
      <h4 className="text-center">{price}</h4>
      <h4 className="text-center">{weight}</h4>
    </div>
  );
};

const FarmerTransactions = () => {
  const { farmerDetails } = useSelector((state: any) => state.farmers);

  const navigation = useNavigate();

  const { name, input_records, service_records, harvest_records } =
    farmerDetails;

  const [selected, setSelected] = useState(input_records);
  const [active, setActive] = useState("input");

  const handleOptionChange = (text: string) => {
    if (text === "input") {
      setSelected(input_records);
      setActive("input");
    } else if (text === "service") {
      setSelected(service_records);
      setActive("service");
    } else {
      setSelected(harvest_records);
      setActive("harvest");
    }
  };

  return (
    <div className="farmer_transactions" id="top">
      <div className="farmer_transactions_inner">
        <div className="close_container">
          <div className="close_btn" onClick={() => navigation(-1)}>
            <MdArrowBack />
          </div>
        </div>

        <h4 className="transactions_title">Transaction Records</h4>

        <h4 className="farmer_name">{name}</h4>

        <div className="type_transaction_indicators_cont">
          <div className="type_transaction_indicators">
            <div className="dropdown dropdown-hover  mr-2">
              <div tabIndex={0} role="button" className=" m-1">
                <FaEllipsisV />
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                <li onClick={() => handleOptionChange("input")}>
                  <span>Input</span>
                </li>
                <li onClick={() => handleOptionChange("service")}>
                  <span>Service</span>
                </li>
                <li onClick={() => handleOptionChange("harvest")}>
                  <span>Harvest</span>
                </li>
              </ul>
            </div>
            {active === "input" && (
              <div className="type_transaction_indicator">
                <div className="indicator green"></div>
                <h4>Input</h4>
              </div>
            )}
            {active === "service" && (
              <div className="type_transaction_indicator">
                <div className="indicator violet"></div>
                <h4>Services</h4>
              </div>
            )}
            {active === "harvest" && (
              <div className="type_transaction_indicator">
                <div className="indicator yellow"></div>
                <h4>Harvest</h4>
              </div>
            )}
          </div>
        </div>

        <div className="transaction_search" data-aos="fade-up">
          <div className="transaction_search_field">
            <MdOutlineSearch />
            <input type="text" placeholder="search transaction" />
          </div>
        </div>

        <div
          className="list_transactions"
          data-aos="fade-up"
          data-aos-delay="100">
          <div className="transaction_table_head">
            <h4 className="text-center">ITEM</h4>
            <h4 className="text-center">TYPE</h4>

            <h4 className="text-center">QUANTITY</h4>
            <h4 className="text-center">PRICE(GHS)</h4>
            <h4 className="text-center">WEIGHT</h4>
          </div>

          {selected?.length === 0 ? (
            <div
              style={{
                width: "100%",
                minHeight: "400px",
                display: "grid",
                placeItems: "center",
              }}>
              No Transactions yet...
            </div>
          ) : (
            selected?.map((transaction: Product, index: number) => {
              const { brand, date, type, price, quantity, weight } =
                transaction;

              return (
                <TransactionTile
                  key={index}
                  brand={brand}
                  date={date}
                  type={type}
                  price={price}
                  quantity={quantity}
                  weight={weight}
                />
              );
            })
          )}
        </div>

        <div className="back_to_top">
          <h4
            style={{ cursor: "pointer" }}
            onClick={() => {
              const topElement = document.getElementById("top");
              if (topElement) {
                topElement.scrollIntoView({ behavior: "smooth" });
              }
            }}>
            Back to top
          </h4>
        </div>
      </div>
    </div>
  );
};

export default FarmerTransactions;
