import React, { useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import FormInput from '../../components/form_input';

const InventoryAdd = () => {

    const navigation = useNavigate();
    interface InventoryForm { itemType : string, itemName : string, description : string, imgUrl : string , quantity : string}
    const [formData,setFormData] = useState<InventoryForm>({
        itemName : '',
        itemType : '',
        description : "",
        imgUrl : "",
        quantity : ""
    });


    const handleSubmit = ()=>{
        // console.log(formData)
    }


  return (
    <div className='inventory_add'>
        <div className="close_container">
            <div className="close_btn"  onClick={()=>navigation(-1)}>
                <MdArrowBack/>
            </div>
        </div>




        <div style={{width : "100%", maxWidth : "600px", margin : "auto",marginTop : "50px", backgroundColor : 'var(--white)', padding : '30px 20px', borderRadius : '15px'}}>
            <div style={{width : "100%", maxWidth : "1200px", marginBottom : "30px"}}>
                <h1>Add Item to Storage</h1>
            </div>
            <FormInput title="Item Type" name="warehouseType" selectValues={["Produce Warehouse"]}  isSelect setValue = {setFormData} data = {formData} />
            <FormInput title="Item Name" name="ItemName" type="text" setValue = {setFormData} data = {formData} />
            <FormInput title="Description" name="description" type="text" setValue = {setFormData} data = {formData} />
            {/* <FormInput title="Item Name" name="ItemName" type="text" setValue = {setFormData} data = {formData} /> */}
            <FormInput title="Quantity" name="quantity" type='number' setValue = {setFormData} data = {formData} />

            <button className='btn' onClick={handleSubmit} style={{marginTop : "30px"}}>
                Continue
            </button>
        </div>

    </div>
  )
}

export default InventoryAdd
