import { useEffect, useState } from "react";
import { HiOutlinePlusSm, HiOutlineRefresh } from "react-icons/hi";
import { MdOutlineSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Loading2 } from "../../components/loading";
import SetupWelcome from "../../components/setup_welcome";
import { axiosInstance } from "../../redux/apis/axios";
import { ADMIN_TOKEN } from "../../state_manager/constants";
// import { filterWarehouseByName } from "../../functions/warehouses";
import '../../styles/warehouses.css'
import { getCachedToken } from "../../service/common";



export interface WarehouseProfile {
    id : string;
    name : string;
    region : string;
    district : string;
    warehouse_type : string;
    number_of_items : string;
}

const TableTile = ({id, name, warehouse_type, number_of_items} : WarehouseProfile)=>{
    const navigate = useNavigate();

    return(
      <tr className="table_tile table-row"  onClick={()=>navigate(id)} >
        <td>{name}</td>
        <td>{warehouse_type}</td>
        <td>{number_of_items}</td>
      </tr>
    )
  }

const Warehouses = ()=>{

    const [warehouses, setWarehouses] = useState<WarehouseProfile[] | null>(null);
    const [pageLoading,setPageLoading] = useState<boolean>(true);
    const navigate = useNavigate();


    const handleInputChange = (e:any)=>{
        const {value} = e.target;
        if(warehouses?.length !== 0){
            // setWarehouses(filterWarehouseByName(warehouses,value));
        }

    }


    useEffect(()=>{

        const token =  getCachedToken();
        axiosInstance.get("admin/api/company/warehouse/list",{headers : {"Authorization" : `Bearer ${token}`}})
        .then(res => {
            if(res.data.code === 200){
                const {msg} = res.data;
                setWarehouses(msg?.data);
                setWarehouses(
                    [
                        {id : "435" , name : "A-House", warehouse_type: "Produce", number_of_items : "34", district : "", region : ""},
                    ]
                )

            }
            setPageLoading(false);
        })
        .catch(_=> { console.log("Error fetch warehouses"); setPageLoading(false) })

    },[])


    if(pageLoading) return <Loading2/>

    if(!warehouses) return <SetupWelcome url="/warehouseadd"/>



    return(
        <div className="warehouses">
            <div className='warehouse_inner'>

            <div className='search_filter_cont' data-aos = "fade-up">
                <div className='search_cont'>
                <MdOutlineSearch className='search_icon'/>
                <input type="text" placeholder='search warehouse' name = "query" onChange={handleInputChange} />
            </div>

            {/* <div className='filter_cont'>
                <select name="" id="" className='dropdown'>
                    <option value="all">Region</option>
                    <option value="Greater Accra">Greater Accra</option>
                    <option value="Greater Accra">Brong Ahafo</option>
                    <option value="Greater Accra">Eastern</option>
                </select>
            </div> */}

            </div>

            <div className='actions_cont'  data-aos = "fade-up" data-aos-delay = "150" >
                <div className='actions_cont_inner'>

                    <div className='actions_btn_cont'>

                    <div className='action_btn' title='refresh table' onClick={()=> window.location.reload()}>
                        <HiOutlineRefresh/>
                    </div>

                    <div className='action_btn' title='add warehouse' onClick={()=>navigate("/warehouseadd")} >
                        <HiOutlinePlusSm title='add farmer'/>
                    </div>
                    </div>
                    <div className='table_pagination_cont'>
                        <div className='table_page_numbers'>
                            <h4>
                                {/* {farmersQuery?.from} - {farmersQuery?.to} of {farmersQuery?.total} */}
                            </h4>

                        </div>

                        <div className='table_page_control'>
                            {/* <div className='control_btn control_inactive' title='Previous Page' onClick={()=>{fetchFarmers(farmersQuery?.previousPageUrl ? farmersQuery.previousPageUrl : "")}} >
                                <MdOutlineChevronLeft/>
                            </div>
                            <div className='control_btn control_inactive' title='Next Page' onClick={()=>{fetchFarmers(farmersQuery?.nextPageUrl ? farmersQuery.nextPageUrl : "")}}>
                                <MdOutlineChevronRight/>
                            </div>

                            <div className='control_btn control_inactive' title='First Page' onClick={()=>{fetchFarmers(farmersQuery?.firstPageUrl ? farmersQuery.firstPageUrl : "")}} >
                                <MdOutlineExpandLess/>
                            </div>
                            <div className='control_btn control_inactive' title='Last Page' onClick={()=>{fetchFarmers(farmersQuery?.lastPageUrl ? farmersQuery.lastPageUrl : "")}}>
                                <MdOutlineExpandMore/>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='list_farmers_cont' data-aos = "fade-up" data-aos-delay = "200">
                {

                    <table>
                        <thead>
                            <tr className="table-row">
                                <th>NAME</th>
                                <th>WAREHOUSE TYPE</th>
                                <th>NUMBER OF ITEMS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                warehouses.length === 0 && <tr className="table-row"><td>No record yet</td><td></td><td></td></tr>
                            }

                            {
                                warehouses.map((warehouse,i)=>{
                                    return(
                                        <TableTile key={i} {...warehouse} />
                                    )
                                })
                            }


                        </tbody>
                    </table>
                }



            </div>

            <div className="back_to_top">
                <h4>Back to top</h4>
            </div>

            </div>
        </div>
    )
}


export default Warehouses;
