import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CycleWelcome from "../../components/cycle_welcome";
import { Loading, Loading2 } from "../../components/loading";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchCycles } from "../../redux/actions/cycles";
import { cyclesActions } from "../../redux/slice/cycles/cyclesSlice";
import FilterMenu from "./components/filter_menu";

const TableTile = ({
  name,
  variety,
  start_date,
  end_date,
  id,
  handleClick,
}: any) => {
  return (
    <tr onClick={() => handleClick(id)} className="table-row">
      <td>{name}</td>
      <td>{variety}</td>
      <td>{start_date}</td>
      <td>{end_date}</td>
    </tr>
  );
};

type CycleStatus = "all" | "pending" | "progress" | "complete";

const GroupCycle = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const [selectedState, setSelectedState] = useState<CycleStatus>("all");
  const [searchParam, setSearchParam] = useState<string>("");
  const [groupDataLoading, setGroupDataLoading] = useState<boolean>(false);
  const [groupCyclesData, setGroupCyclesData] = useState<any>([]);

  const navigate = useNavigate();

  const { cycles, isLoading, meta, failedMsg } = useSelector(
    (state: any) => state.cycles
  );

  useEffect(() => {
    if (searchParam === "") {
      setGroupCyclesData(cycles);
    } else {
      const filteredData = cycles.filter((cycle: any) =>
        cycle.name.toLowerCase().includes(searchParam.toLowerCase())
      );
      setGroupCyclesData(filteredData);
    }
  }, [cycles, searchParam]);

  const adminId = useSelector((state: any) => state.admin.user.id);
  const companyId = useSelector((state: any) => state.admin.user.id);

  if (failedMsg) console.log(failedMsg);

  useEffect(() => {
    const fetchCycleData = {
      admin_id: adminId,
      company_id: companyId,
      is_group: 1,
    };
    dispatch(fetchCycles(fetchCycleData));
  }, [dispatch, adminId]);

  const handleClick = (id: any) => {
    dispatch(cyclesActions.CycleDetails(id));
    navigate("details");
  };

  if (isLoading) return <Loading2 />;

  const handleFilter = (state: CycleStatus) => {
    setSelectedState(state);
    // Add filter logic here
  };

  if (!cycles) return <CycleWelcome />;
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center" style={{ backgroundColor: "white" }}>
          <div className="flex items-center px-14 py-3 gap-4 rounded-xl  border-gray-300 border text-gray-800">
            <MdSearch className="h-6 w-6" />
            <input
              type="text"
              value={searchParam}
              placeholder="Search group name..."
              className="text-lg w-full bg-white"
              onChange={(e) => setSearchParam(e.target.value)}
            />
          </div>
        </div>

        <div className="flex gap-8 items-center">
          <FilterMenu
            handleFilter={handleFilter}
            selectedState={selectedState}
          />
          <div
            className="bg-primary rounded-xl  px-6 py-3 text-white cursor-pointer"
            onClick={() => navigate("cycleadd")}>
            Add cycle
          </div>
        </div>
      </div>

      <div id="farmer_table_top" className="list_farmers_cont">
        <div className="table_loading_cont">
          {groupDataLoading && (
            <div className="table_loading_cont_inner">
              <div className="table_loading_indicator"></div>
            </div>
          )}
        </div>
      </div>

      {/* CYCLES TABLE */}
      <div className="my-8" data-aos="fade-up" data-aos-delay="200">
        {!cycles ? (
          <div
            style={{
              width: "100%",
              minHeight: "400px",
              display: "grid",
              placeItems: "center",
            }}>
            No Cycle yet...
          </div>
        ) : (
          <table>
            <thead className="bg-table_head w-full">
              <tr className="rounded-2xl">
                {["Name", "Variety", "Start Date", "End Date"].map(
                  (tableHeadContent, i) => (
                    <th key={i} className=" uppercase">
                      {tableHeadContent}
                    </th>
                  )
                )}
              </tr>
            </thead>

            <tbody>
              {groupCyclesData.length === 0 && searchParam !== "" ? (
                <tr className="table-row">
                  <td colSpan={4}>No Group with such name exists</td>
                </tr>
              ) : (
                groupCyclesData.length === 0 && (
                  <tr className="table-row">
                    <td colSpan={4}>No data yet</td>
                  </tr>
                )
              )}
              {groupCyclesData?.map((cycle: any, i: any) => (
                <TableTile key={i} {...cycle} handleClick={handleClick} />
              ))}
            </tbody>
          </table>
        )}

        {groupCyclesData && (
          <div className="back_to_top">
            <h4>Back to top</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupCycle;
